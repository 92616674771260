import { Form } from 'react-bootstrap'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import { useState } from 'react'

interface EmailManuallyIncomeStatementModalProps {
  show: boolean
  handleClose: (emailList: string[]) => void
}

const EmailManuallyIncomeStatementModal = ({ show, handleClose }: EmailManuallyIncomeStatementModalProps) => {
  const [emailList, setEmailList] = useState<string>()

  const handleSend = () => {
    handleClose(emailList?.replaceAll(',', ';').split(';') ?? [])
  }

  return (
        <Modal show={show} onHide={() => { handleClose([]) }}>
          <Modal.Header closeButton>
            <Modal.Title>Надіслати PDF поштою</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <Form>
                <Form.Group className="mb-3">
                <Form.Label>Email</Form.Label>
            <Form.Control type="email" onChange={(e: any) => { setEmailList(e.target.value) }}/>
            </Form.Group>
            </Form>
          </Modal.Body>

          <Modal.Footer>
            <Button variant="reject" onClick={(e: any) => { handleClose([]) }}>Close</Button>
            <Button variant="success" onClick={handleSend}>Save</Button>
                </Modal.Footer>
        </Modal>
  )
}

export default EmailManuallyIncomeStatementModal

import type { IDashboardRowData } from '../../interfaces/dashboard'
import { useState } from 'react'
import type { IDocument } from '../../interfaces/document'
import api from '../../api'
import { Col, Container, Row } from 'react-bootstrap'
import { FaEye, FaFileAlt } from 'react-icons/fa'
import DocumentViewModal from '../document/documentViewModal'

export interface DashboardTableRowDataProps {
  data: IDashboardRowData
}
const DashboardTableRowPeriodData = ({ data }: DashboardTableRowDataProps) => {
  const [showDocumentViewModal, setShowDocumentViewModal] = useState<boolean>(false)
  const [document, setDocument] = useState<IDocument | null>(null)

  const handleViewDocument = (documentId: number) => {
    api.get(`/document/${documentId}`)
      .then(res => {
        setDocument(res.data as IDocument)
        setShowDocumentViewModal(true)
      })
      .catch(e => {
        // TODO: buttify error
        console.log(e)
      })
  }

  const handleViewReceipt = (receiptId: number) => {
    api.get(`/receipt/${receiptId}`)
      .then(res => {
        const doc = res.data as IDocument
        doc.type = 'J1499102'
        setDocument(doc)
        setShowDocumentViewModal(true)
      })
      .catch(e => {
        // TODO: buttify error
        console.log(e)
      })
  }

  const handleCloseDocumentViewModal = () => {
    setShowDocumentViewModal(false)
  }

  return (
        <Container className="dashboard-ceil">
            <Row>
                <Col>Сума доходу</Col>
                <Col className="text-end">{data?.incomeAmount?.toLocaleString('uk-UA', { minimumFractionDigits: 2 })}</Col>
            </Row>
            <Row>
                <Col>Податок</Col>
                <Col className="text-end">{data?.taxAmount.toLocaleString('uk-UA', { minimumFractionDigits: 2 })}</Col>
            </Row>
            <Row hidden={(data?.reportPeriodId ?? 0) !== 20}>
                <Col>ЄСВ</Col>
                <Col className="text-end">{data?.socialTaxAmount?.toLocaleString('uk-UA', { minimumFractionDigits: 2 })}</Col>
            </Row>
            <Row>
                <Col className="text-end">
                    {data?.documents?.[0].id !== undefined &&
                        <FaEye className="mx-1" style={{ color: 'grey' }} onClick={() => { handleViewDocument(data.documents?.[0].id ?? 0) }} size={18}/>
                    }
                    {data?.documents?.[0]?.receipts?.[0]?.id !== undefined &&
                        <FaFileAlt size={16} onClick={() => { handleViewReceipt(data.documents?.[0].receipts?.[0].id ?? 0) }} className={((data.documents?.[0]?.receipts?.[0]?.status >= 0) ? 'receipt-success' : 'receipt-error')}/>
                    }

                    {data?.documents?.[0]?.receipts?.[1]?.id !== undefined &&
                        <FaFileAlt size={16} onClick={() => { handleViewReceipt(data.documents?.[0].receipts?.[1]?.id ?? 0) }} className={((data.documents?.[0]?.receipts?.[1]?.status >= 0) ? 'receipt-success' : 'receipt-error')}/>
                    }

                </Col>
            </Row>
            <DocumentViewModal show={showDocumentViewModal} doc={document!} handleClose={handleCloseDocumentViewModal}/>
        </Container>
  )
}

export default DashboardTableRowPeriodData

import { Button, Col, Dropdown, Form, NavItem, NavLink, Row } from 'react-bootstrap'

import { useEffect, useState } from 'react'
import api from '../../api'
import DocumentViewModal from '../document/documentViewModal'
import { type IDocument } from '../../interfaces/document'
import { FaEye, FaFileAlt } from 'react-icons/fa'
import { convertISODateToDateString, convertISODateToDatetimeString } from '../../utils/convert'
import { BsFiletypeXml } from 'react-icons/bs'
import type IAccount from '../../interfaces/account'

export interface DigitalSignatureTableRowProps {
  row: IAccount
  checked: boolean
  handleRowSelectionChanged: (row: IAccount | null) => void
}

const DigitalSignatureTableRow = ({ row, checked, handleRowSelectionChanged }: DigitalSignatureTableRowProps) => {
  const [selected, setSelected] = useState<boolean>(false)
  const [showDocumentViewModal, setShowDocumentViewModal] = useState<boolean>(false)
  const [document, setDocument] = useState<IDocument | null>(null)

  const mainKey = row.keyFiles?.find((item) => { return item.isMain === true })
  const newKey = row.keyFiles?.find((item) => { return item.isNew === true })

  useEffect(() => {
    setSelected(checked)
    setDocument(null)
  }, [checked])

  useEffect(() => {
    row.isChecked = selected
    handleRowSelectionChanged(row)
  }, [selected])

  const handleViewDocument = (documentId: number) => {
    api.get(`/document/${documentId}`)
      .then(res => {
        setDocument(res.data as IDocument)
        setShowDocumentViewModal(true)
      })
      .catch(e => {
        console.log(e)
      })
  }

  const handleViewReceipt = (receiptId: number) => {
    api.get(`/receipt/${receiptId}`)
      .then(res => {
        const doc = res.data as IDocument
        doc.type = 'J1499102'
        setDocument(doc)
        setShowDocumentViewModal(true)
      })
      .catch(e => {
        console.log(e)
      })
  }

  const handleCreateDocument = (id: number) => {
    api.post(`/digitalSignature/${id}/newDocument`)
      .then(res => {
        const document = res.data as IDocument
        if (row.documents === undefined) {
          row.documents = [document]
        } else {
          row.documents?.unshift(document)
        }
        setDocument(document)
        setShowDocumentViewModal(true)
      })
      .catch(e => {
        console.log(e)
      })
  }

  const handleSend = (documentId: number) => {
    api.post(`/document/${documentId}/send`)
      .then(res => {
        console.log(res)
      })
      .catch(e => {
        console.log(e)
      })
  }

  const handleCloseDocumentViewModal = () => {
    setShowDocumentViewModal(false)
  }

  return (
      <tr key={row.id}>
          <td className="align-middle"><Form.Check.Input checked={selected} onChange={() => {
            setSelected(!selected)
          }}/></td>
          <td>{row.ipnCode}</td>
          <td>
              <a href={window.location.origin + `/account/${row.id}`} target="_blank" rel="noreferrer">{row.name}</a>
          </td>
          <td className="text-end">{mainKey?.issuer} - {convertISODateToDateString(mainKey?.certEndTime)}</td>
          <td className="text-end">{newKey?.issuer} - {convertISODateToDateString(newKey?.certEndTime)}</td>
          <td className="text-end">
              <Row>
                  <Col md={3}>
                      {row.documents?.[0].id !== undefined &&
                          <FaEye className="mx-1" style={{ color: 'grey' }} onClick={() => {
                            handleViewDocument(row.documents?.[0].id ?? 0)
                          }} size={18}/>
                      }
                  </Col>
                  <Col md={4} style={{ display: 'flex' }} className="p-1">
                      {row.documents?.[0]?.receipts?.[0]?.id !== undefined &&
                          <FaFileAlt size={16} onClick={() => {
                            handleViewReceipt(row.documents?.[0].receipts?.[0].id ?? 0)
                          }}
                                     className={((row.documents?.[0]?.receipts?.[0]?.status >= 0) ? 'receipt-success' : 'receipt-error')}/>
                      }

                      {row.documents?.[0]?.receipts?.[1]?.id !== undefined &&
                          <FaFileAlt size={16} onClick={() => {
                            handleViewReceipt(row.documents?.[0].receipts?.[1]?.id ?? 0)
                          }}
                                     className={((row.documents?.[0]?.receipts?.[1]?.status >= 0) ? 'receipt-success' : 'receipt-error')}/>
                      }
                  </Col>
                  <Col md={4}>
                      <Dropdown as={NavItem} style={{ marginRight: 10 }}>
                          <Dropdown.Toggle as={NavLink}> docs1
                          </Dropdown.Toggle>
                          <Dropdown.Menu style={{ left: -200, width: 350 }}>
                              <Dropdown.Item onClick={() => {
                                handleCreateDocument(row.id!)
                              }}> +Додати</Dropdown.Item>
                              <Dropdown.Divider/>
                              {row.documents?.map(document => {
                                return (<Dropdown.Item key={document.id} style={{ display: 'flex' }} as={'div'}>
                                      <div className="my-1 mx-2" onClick={() => {
                                        handleViewDocument(document.id)
                                      }}>
                                          {document.type} - {convertISODateToDatetimeString(document.createdAt)}
                                      </div>

                                      <div className="pl-2 pe-4 pt-1">
                                          <a href={process.env.REACT_APP_API_URL + `/document/${document.id}/xml`}>
                                              <BsFiletypeXml size={20}
                                                             className="text-success d-flex flex-row-reverse"/>
                                          </a>
                                      </div>

                                      {document.status === 'valid' &&
                                          <Button className="add-button" size="sm" onClick={() => {
                                            handleSend(document.id)
                                          }}>Send</Button>}

                                      <div className="pt-2" style={{ display: 'flex' }}>
                                          {document.receipts?.map(receipt => {
                                            return (<FaFileAlt size={16} key={receipt.id} onClick={() => {
                                              handleViewReceipt(receipt.id ?? 0)
                                            }}
                                                                 className={((receipt?.status >= 0) ? 'receipt-success' : 'receipt-error')}/>)
                                          })}
                                      </div>
                                  </Dropdown.Item>)
                              })}
                          </Dropdown.Menu>
                      </Dropdown>
                  </Col>
              </Row>
              <DocumentViewModal show={showDocumentViewModal} doc={document!}
                                 handleClose={handleCloseDocumentViewModal}/>
          </td>
      </tr>
  )
}

export default DigitalSignatureTableRow

import { toast } from 'react-toastify'
import api from '../../api'
import { type IAccountKeyFile } from '../../interfaces/account'
import iconTax from '../../assets/icon-tax.png'
import iconPfu from '../../assets/icon-pfu.png'

/* global chrome */
export interface KeyFileLoginProps {
  keyFile: IAccountKeyFile
}
const extensionId = 'aoiiaeegkeeonghhoefpckcjcgbhmibf'
const webstoreUrl = `https://chromewebstore.google.com/detail/startax/${extensionId}`

const KeyFileLogin = ({ keyFile }: KeyFileLoginProps) => {
  const extensionLogin = (endpoint: string, key: IAccountKeyFile) => {
    if (chrome.runtime === undefined) {
      toast.error(<div>Необхідно встановити розширення:
                <a href={webstoreUrl} download target="_blank" rel="noreferrer">startax.crx</a>
            </div>, {
        position: 'bottom-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: false,
        pauseOnHover: true,
        draggable: false,
        progress: undefined
      })
    }

    api.get(`/keyFile/${key.id}/base64`)
      .then(res => {
        const signFileData = res.data

        chrome.runtime.sendMessage(
          extensionId,
          {
            signFileName: key.name,
            endpoint,
            signFileData,
            signFilePass: key.password,
            signFileACSK: key.issuer
          },
          (response) => {
            if (response !== 'OK') {
              toast.error(`Error: ${response}`, {
                position: 'bottom-right',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: false,
                pauseOnHover: true,
                draggable: false,
                progress: undefined
              })
            }
          }
        )
      })
      .catch(e => {
        console.log(e)
      })
  }

  return (
        <div className="d-flex" style={{ paddingTop: 1 }}>
            <img style={{ marginLeft: 10 }} src={iconTax} onClick={() => {
              extensionLogin('tax.gov.ua', keyFile)
            }} height={16} alt={'https://cabinet.tax.gov.ua/'}/>
            <img style={{ marginLeft: 10 }} src={iconPfu} onClick={() => {
              extensionLogin('pfu.gov.ua', keyFile)
            }} height={18} alt={'https://portal.pfu.gov.ua/'}/>
        </div>
  )
}

export default KeyFileLogin

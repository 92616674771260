import { Button, Container, Form, FormSelect, InputGroup, Row, Stack } from 'react-bootstrap'
import { FaSearch, FaTimes } from 'react-icons/fa'
import { useEffect, useState } from 'react'
import DashboardTable from './dashboardTable'
import './dashboard.scss'
import { type IDashboardRow } from '../../interfaces/dashboard'
import { useSearchParams } from 'react-router-dom'
import { getDefaultYear, getYearsList } from '../../utils/reportPeriod'
import api from '../../api'

const Dashboard = () => {
  const [searchParams, setSearchParams] = useSearchParams()
  const [searchString, setSearchString] = useState<string>(searchParams.get('ipnCode') ?? '')
  const [reportYear, setReportYear] = useState<number>(getDefaultYear(searchParams))
  const [selectedRows, setSelectedRows] = useState<IDashboardRow[] | null>(null)
  const [search, setSearch] = useState<string>(searchParams.get('search') ?? '')
  const [total, setTotal] = useState<number>(0)

  useEffect(() => {
    if (searchString !== '') {
      searchParams.set('search', searchString)
      setSearchParams(searchParams)
    } else if (searchParams.has('search')) {
      searchParams.delete('search')
      setSearchParams(searchParams)
    }
  }, [searchString])

  useEffect(() => {
    searchParams.set('reportYear', String(reportYear))
    setSearchParams(searchParams)
  }, [reportYear])

  useEffect(() => {
    const timeOutId = setTimeout(() => {
      setSearchString(search)
    }, 300)
    return () => { clearTimeout(timeOutId) }
  }, [search])

  const handleSelectionChanged = (data: IDashboardRow[] | null) => {
    setSelectedRows(data)
  }

  const handleTotal = (value: number) => {
    setTotal(value)
  }

  const handleUpdateDebt = () => {
    api.post('dashboard/updateDebt')
      .then(res => {
      })
      .catch(e => {})
  }

  return (

    <Container className="pageContainer dashboard">
        <Row>
            <Stack direction="horizontal">
                <div className="mx-4 pt-3 lh-1">
                    <p className="fs-3 fw-bold">{total}</p>
                </div>
                <div className="p-2">
                    <FormSelect defaultValue={reportYear} onChange={(e: any) => {
                      setReportYear(e.target.value)
                    }}>
                        {getYearsList().map((year) => {
                          return (
                                <option key={year}>{year}</option>
                          )
                        })}
                    </FormSelect>
                </div>
                <div className="p-2">
                    <InputGroup className="search-container">
                        <Form.Control
                            className="search-input"
                            placeholder="Пошук..."
                            value={search}
                            onChange={(e: any) => {
                              setSearch(e.target.value)
                            }}
                        />
                        {search !== ''
                          ? <FaTimes className="icon" onClick={(e: any) => {
                            setSearch('')
                          }}/>
                          : <FaSearch className="icon"/>}
                    </InputGroup>
                </div>
                <div className="p-2 ms-auto">
                    <Button className="add-button" disabled={true}>Відправити квитанцію({selectedRows?.length})</Button>
                    <Button className="add-button mx-3" onClick={(e) => { handleUpdateDebt() }}>Оновити стан розрахунків</Button>
                </div>
            </Stack>
        </Row>
        <Row className="account-table">
            <DashboardTable searchParams={searchParams}
                            handleSelectionChanged={handleSelectionChanged} handleTotal={handleTotal}></DashboardTable>
        </Row>
    </Container>

  )
}

export default Dashboard

import './taxTypes'
import type TaxDocumentProps from '../../interfaces/taxDocumentProps'

const FTEXT = ({ doc }: TaxDocumentProps) => {
  const body = doc.taxDoc?.Body as string

  return (
        <div style={{whiteSpace: "pre-line"}}>
            {body}
        </div>
  )
}

export default FTEXT

import moment from 'moment'

export const convertISODateToDatetimeString = (date: string | undefined | null | Date): string => {
  if (date === undefined || date === null) { return '' }
  const d = moment(date)
  return d.format('DD.MM.YYYY HH:mm')
}

export const convertISODateToDateString = (date: string | undefined | null | Date): string => {
  if (date === undefined || date === null) { return '' }
  const d = moment(date)
  return d.format('DD.MM.YYYY')
}

export const convertStringToBoolean = (val: string | null): boolean | null => {
  switch (val) {
    case 'true':
      return true
    case 'false':
      return false
  }
  return null
}

export const normalizeKeyFileId = (val: string | undefined | null): string => {
  if (val === undefined || val === null) { return '' }
  return val.replace(/\s/g, '').toLocaleLowerCase()
}

export interface IDebt {
  id: number
  updatedAt?: string
  reportYear: number
  code: number
  narah0: number
  narahEnd: number
  nedoim0: number
  nedoimEnd: number
  perepl0: number
  pereplEnd: number
  penia0: number
  peniaEnd: number
  splbd0: number
  splbdEnd: number
}

export const debtByCode = (debt: IDebt[] | undefined, code: number): IDebt | undefined => {
  return debt?.find((item) => { return item.code === code })
}

export const getDefaultYear = (searchParams: URLSearchParams) => {
  const reportYear = searchParams.get('reportYear') ?? ''
  if (reportYear !== '') {
    return Number(reportYear)
  }
  const now = new Date()
  return now.getMonth() < 2 ? now.getFullYear() - 1 : now.getFullYear()
}

export const getDefaultPeriod = (searchParams: URLSearchParams) => {
  const reportPeriod = searchParams.get('reportPeriodId') ?? ''
  if (reportPeriod !== '') {
    return Number(reportPeriod)
  }

  let periodId: number = 4
  const currentMonth = (new Date()).getMonth()
  if ([2, 3, 4].includes(currentMonth)) {
    periodId = 4
  } else if ([5, 6, 7].includes(currentMonth)) {
    periodId = 9
  } else if ([8, 9, 10].includes(currentMonth)) {
    periodId = 14
  } else if ([11, 0, 1].includes(currentMonth)) {
    periodId = 20
  }
  return periodId
}

export const getYearsList = (): number[] => {
  const res: number[] = []
  for (let y = 2024; y <= (new Date()).getFullYear(); y++) {
    res.push(y)
  }
  return res
}

import { Col, Container, Form, Row } from 'react-bootstrap'
import './dashboardTableRow.scss'
import { useEffect, useState } from 'react'
import type { IDashboardRow } from '../../interfaces/dashboard'
import DashboardTableRowPeriodData from './dashboardTableRowPeriodData'
import { debtByCode } from '../../interfaces/debt'
import KeyFileLogin from '../keyFileLogin/keyFileLogin'

export interface DashboardTableRowProps {
  row: IDashboardRow
  checked: boolean
  handleRowSelectionChanged: (row: IDashboardRow | null) => void
}

const DashboardTableRow = ({ row, checked, handleRowSelectionChanged }: DashboardTableRowProps) => {
  const [selected, setSelected] = useState<boolean>(false)

  useEffect(() => {
    setSelected(checked)
  }, [checked])

  useEffect(() => {
    row.isChecked = selected
    handleRowSelectionChanged(row)
  }, [selected])

  const getTax = (code: number): number => {
    const tax = debtByCode(row.debt, code)
    if (tax === undefined) {
      return 0
    }
    return tax.pereplEnd - tax.nedoimEnd
  }

  return (
      <tr key={row.id}>
          <td className="align-middle"><Form.Check.Input checked={selected} onChange={() => {
            setSelected(!selected)
          }}/></td>
          <td>
              <div className="d-flex">
                  {row.ipnCode}
                  {
                      ((new Date(row.keyFiles![0].certEndTime)).getTime() > (new Date()).getTime()) &&
                      <KeyFileLogin keyFile={row.keyFiles![0]}/>
                  }
              </div>
          </td>
          <td>
              <a href={window.location.origin + `/account/${row.id}`} target="_blank"
                 rel="noreferrer">{row.name}</a>
          </td>
          <td><DashboardTableRowPeriodData data={row.data.find((item) => {
            return item.reportPeriodId === 4
          })!}></DashboardTableRowPeriodData></td>
          <td><DashboardTableRowPeriodData data={row.data.find((item) => {
            return item.reportPeriodId === 9
          })!}></DashboardTableRowPeriodData></td>
          <td><DashboardTableRowPeriodData data={row.data.find((item) => {
            return item.reportPeriodId === 14
          })!}></DashboardTableRowPeriodData></td>
          <td><DashboardTableRowPeriodData data={row.data.find((item) => {
            return item.reportPeriodId === 20
          })!}></DashboardTableRowPeriodData></td>
          <td><Container className="dashboard-ceil">
              <Row>
                  <Col>ЄП</Col>
                  <Col className="text-end">{getTax(18050400).toLocaleString('uk-UA', { minimumFractionDigits: 2 })}</Col>
              </Row>
              <Row>
                  <Col>ВЗ</Col>
                  <Col className="text-end">{getTax(11011700).toLocaleString('uk-UA', { minimumFractionDigits: 2 })}</Col>
              </Row>
              <Row>
                  <Col>ЄСВ</Col>
                  <Col className="text-end">{getTax(71040000).toLocaleString('uk-UA', { minimumFractionDigits: 2 })}</Col>
              </Row>
              <Row>
                  <Col>Нерух.</Col>
                  <Col className="text-end">{getTax(18010200).toLocaleString('uk-UA', { minimumFractionDigits: 2 })}</Col>
              </Row>
          </Container></td>
      </tr>
  )
}

export default DashboardTableRow

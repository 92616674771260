import Modal from 'react-bootstrap/Modal'
import F1300204 from './F1300204'
import { type IDocument } from '../../interfaces/document'
import './documentViewModal.scss'
import F0103308 from './F0103308'
import F1499102 from './F1499102'
import F1391103 from './F1391103'
import FTEXT from './FTEXT'
import F1391104 from './F1391104'
import { ButtonGroup, ToggleButton } from 'react-bootstrap'
import { useEffect, useState } from 'react'
import F0133108 from './F0133108'
import { getDocumentName } from '../../utils/document'

interface DocumentViewModalProps {
  doc: IDocument
  show: boolean
  handleClose: () => void
}

const DocumentViewModal = ({ show, doc, handleClose }: DocumentViewModalProps) => {
  const [activeDoc, setActiveDoc] = useState<IDocument>(doc)
  const [activeDocId, setActiveDocId] = useState<number>(0)

  useEffect(() => {
    if (doc !== null && (doc?.taxDoc?.Head === undefined || doc.taxDoc.Head === null)) {
      doc.type = 'FTEXT'
    }
    setActiveDoc(doc)
  }, [doc])

  useEffect(() => {
    setActiveDocId(activeDoc?.id)
  }, [activeDoc])

  return (
        <Modal show={show} dialogClassName="modal-90w" onHide={() => { handleClose() }}>
            <Modal.Header closeButton>
                <ButtonGroup size="sm">
                     <ToggleButton
                        key={doc?.id}
                        style={{ minWidth: '9em' }}
                        id={`filter-${doc?.id}`}
                        type="radio"
                        variant={'outline-primary'}
                        name="filter"
                        value={doc?.id}
                        checked={activeDocId === doc?.id}
                        onChange={(e) => { setActiveDoc(doc) }}
                     ><span>{doc?.type} - </span>{getDocumentName(doc?.type)}</ToggleButton>
                     {doc?.attachments?.map((item, idx) => (
                        <ToggleButton
                            key={item?.id}
                            style={{ minWidth: '9em' }}
                            id={`filter-${item.id}`}
                            type="radio"
                            variant={'outline-primary'}
                            name="filter"
                            value={item?.id}
                            checked={activeDocId === item?.id}
                            onChange={(e) => { setActiveDoc(item) }}
                        ><span>{item?.type} - </span>{getDocumentName(item?.type)}</ToggleButton>
                     ))}
                </ButtonGroup>
            </Modal.Header>
            <Modal.Body>
                {
                    {
                      F1300204: <F1300204 doc={activeDoc}/>,
                      F0103308: <F0103308 doc={activeDoc}/>,
                      F0133108: <F0133108 doc={activeDoc}/>,
                      F1499102: <F1499102 doc={activeDoc}/>,
                      J1499102: <F1499102 doc={activeDoc}/>,
                      F1391103: <F1391103 doc={activeDoc}/>,
                      F1391104: <F1391104 doc={activeDoc}/>,
                      FTEXT: <FTEXT doc={activeDoc}/>
                    }[activeDoc?.type]
                }
            </Modal.Body>
        </Modal>
  )
}

export default DocumentViewModal
